/*
  Site container
*/

.container {
  margin-left: auto;
  margin-right: auto;
  width: 700px;
}

.content-container {
  margin-bottom: 4rem;
}

/*48em*/

@media (max-width: $container-responsive-width) {
  .container {
    width: 90vw;
  }
}

/*
  Header
*/

.header {
  padding-top: 1.0rem;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
  font-family: $sans-serif-font-family;
}

.header a:hover {
  color: $body-color;
}

.header-title a {
  font-size: 2rem;
  font-weight: 600;
  font-style: italic;
  text-decoration: none;
  color: $body-color;
}

.header-subtitle {
  color: $light-gray-color;
}

.gradient-line {
  display: block;
  height: 3px;
  background: linear-gradient(to right, #8C52FF 35%, #5CE1E6);
  border: none;
  margin-top: 10px;
}

/*
  Menu
*/

.menu {
  padding-top: 0.5rem;
  display: block;
  overflow: hidden;
}

.menu-content {
  float: left;
}

.menu-content a {
  color: $light-gray-color;
  font-size: 1rem;
  padding-right: 10px;
  text-decoration: none;
  font-weight: 400;
}

.social-icons {
  float: right;
}

.social-icons a {
  color: $light-gray-color;
  font-size: 1rem;
}

@media (max-width: $elements-responsive-width) {
  .social-icons {
    float: right;
    padding-top: 0px;
    display: none;
  }
  .menu-content {
    margin-bottom: 5px;
  }

  .menu-content a {
    font-size: 1rem;
    padding-right: 10px;
  }

  .menu {
    padding-top: 0.3rem;
  }

  .header {
    padding-top: 1.0rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }

  .footer-description {
    line-height: 18px;
  }

}

/*
  Footer
*/

.footer {
  color: $light-gray-color;
  text-align: center;
}

.footer-social-icons a {
  color: $light-gray-color;
  padding: 10px;
  text-decoration: none;
}

.footer a:hover {
  color: $dark-gray-color;
}

.footer-description {
  display: block;
  margin-top:3px;
  color: $light-gray-color;
  font-family: $sans-serif-font-family;
  font-size:0.8rem;
}

.footer-description a {
  text-decoration: none;
  color: $light-gray-color;
}

/* Misc */

.center-image
{
    margin: 0 auto;
    display: block;
}