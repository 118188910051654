/*
  Posts
*/

.posts-container {
  margin-bottom: 5rem;
  padding: 0px;
  list-style: none;
}

.posts {
  display: flex;
  flex-direction: column;
}

.post {
  display: flex;
  align-items: flex-start; /* Aligns items in a row to the start of the flex container */
  margin-bottom: 50px; /* Space between posts */
}

.post-image img {
  width: 120px;  /* Fixed square size */
  height: 120px; /* Fixed square size */
  object-fit: cover; /* Ensures the image covers the area, useful for non-square images */
  margin-left: 50px; /* Space between the image and the content */
}

.post-content {
  flex: 1; /* Takes up the remaining space */
}

.post-content h2 {
  margin-block-start: 0em; 
}

.post-meta {
  font-size: 12px;
  color: grey;
}

.post-lead {
  margin-top:15px;
  margin-bottom: 10px;
}

.post-lead a {
  display: block; 
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

@media (max-width: 600px) {
 /*
  .post {
    flex-direction: column; 
  } */

  .post-image img {
    width: 70px;
    height: 70px;
    margin-left: 15px;
    /* margin-bottom: 10px; Space between the image and the content on small screens */
  }

  .post-content {
    width: 100%; /* Full width on smaller screens */
  }
}



/*
  Thumbnail
*/

.thumbnail-container {
  max-width: 100%;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 25px;
}

.thumbnail-container img{
  margin-top: -11.5%;
  margin-bottom: -11.5%;
}

/*
  Pagination
*/

.pagination {
  overflow: hidden;
  font-family: $sans-serif-font-family;
  text-align: center;
}

.pagination a {
  text-decoration: none;
}

.pagination-button {
  color: $light-gray-color;
  border: 1px solid $pagination-button-color;
  padding: 15px 45px;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.pagination-active {
  color: $link-color;
}

.pagination-active:hover {
  background-color: $pagination-button-color;
}

.no-margin-p p {
  margin: 0;
}

.about-img {
  float: left;
  margin-right: 20px; /* Adjust as needed */
  margin-bottom: 0px; /* Adjust as needed */
  max-width: 29%; /* Adjust as needed */
  height: auto;
}

@media (max-width: $elements-responsive-width) {

  .about-img {
    max-width: 38%; /* Adjust as needed */
  }
}