/*
  Post
*/


.post-header-date {
  font-size: 12px;
  margin-top: 10px;
  color: $light-gray-color;
  padding-bottom: 30px;
}

.post-date {
  display: block;
  margin-top:3px;
  margin-bottom: 1rem;
  color: $light-gray-color;
  font-family: $sans-serif-font-family;
  font-size:0.8rem;
}

@media (max-width: $elements-responsive-width) {
  .posts h1 {
    font-size: 1.5rem;
  }

  .post-header-date {
    margin-top: 5px;
  }

}

.related {
  padding-bottom: 2rem;
  padding-top: 1rem;
}

.related-posts {
  padding-left: 0px;
  list-style: none;
}

.related-posts a {
  text-decoration: none;
  color:$purple-color;
  padding-bottom:5px;
}

.related-posts-link {
  font-size: 18px;
}