/*
  Base
*/

html {
  overflow-y: scroll;
}

body {
  font-family: $sans-serif-font-family;
  font-size: 16px;
  color: $body-color;
  line-height: 24px;
  font-weight: 400;
}

@media (max-width: $elements-responsive-width) {
  body {
    font-size: 16px;
    line-height: 1.5rem;
  }
  body h1 {
    font-size: 2rem;
    line-height: 2rem;
  }

  h2 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

h1 {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-block-end: 0em;   
  margin-inline-start: 0px; 
  margin-inline-end: 0px;
}

h1 a {
  text-decoration: none;
}

h2 {
  font-size: 24px;
  line-height: 2rem;
  margin-block-start: 2.5em;  
  margin-block-end: 1em;   
}

h2 a {
  text-decoration: none;
}

h3 {
  font-size: 20px;
  margin-block-start: 2em; 
}

h1, h2, h3, h4, h5, h6 {
  font-family: $sans-serif-font-family;
  color: $body-color;
}

p {
  font-size: 18px;
  line-height: 30px;
}

li {
  font-size: 18px;
  line-height: 32px;
}

a {
  text-decoration: underline;
  color: $body-color;
}

a:hover {
  border-bottom: none;
}

article a {
  text-decoration: none;
  color: $purple-color;
}

article a:hover {
  text-decoration: underline;
  color: $light-gray-color;
}

p a {
  text-decoration: none;
  color: $purple-color;
}

p a:hover {
  text-decoration: underline;
  color: $light-gray-color;
}


img {
  max-width: 100%;
  border-radius: 5px;
}

blockquote {
  margin: 10px 20px 10px;
  padding: 0px 15px;
  border-left: 0.25em solid $purple-color;
  color: $body-color;
  line-height: 1.5;
  font-style: italic;
}

hr {
  border-top: 1px solid $extra-light-gray-color;
}

/*
  Tables
*/

table {
  border-collapse: collapse;
  margin-bottom: 30px;
  width: 100%;
}

table, th, td {
  border: 1px solid $light-gray-color;
}

th, td {
  padding: 10px;
  text-align: left;
}

strong {
  font-weight: 700;
}